import React from 'react'
import { ContactsQuery } from '@app/api/EntityQuery'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { useRequestOnMount } from '@fsg/next-auth/useRequest'
import { ContactResponse, APIResponse } from '@app/types'
import Link from 'next/link'
import { ExternalLink } from '@fsg/icons'
import { ReadOnlyField } from './ReadOnlyField'
import { placeholder, routes } from '@app/utils'
import { LoadingSpinner } from '@fsg/gui-bits'
import { User } from '@fsg/next-auth/types'
import { useAppContextSelector } from '../AppContext'

const Display = ({ contact }: { contact: ContactResponse }) => (
  <Link href={routes.contact.base(contact.id)}>
    <div>
      <div className="flex w-full">
        <span className="text-sm text-gray-800">{contact.fullName}</span>
        <ExternalLink className="ml-auto inline" />
      </div>
      <p className="text-sm text-gray-700">{contact.primaryEmail?.address}</p>
    </div>
  </Link>
)

function buildUserContactQuery(userId: string) {
  const contactQuery = new ContactsQuery()
  const q = new FilterQueryParams()
  const filter = q.textFilter({
    field: 'fsgEmployeeId',
    value: userId,
    operation: 'equals',
  })
  contactQuery.filter(filter)
  return contactQuery.getPath()
}

type Props = {
  createdBy: User | null
}

export function CreatedByField({ createdBy }: Props) {
  const session = useAppContextSelector((state) => state.session)
  const userContact = useAppContextSelector((state) => state.userContact)
  const employeeNumber = createdBy?.employeeNumber ?? null
  const createdByCurrentUser = session.user.employeeNumber === employeeNumber

  // Don't enable request if there's no createdBy (unnecessary)
  // or if the data was created by the logged in user (since we should already have the Contact in AppContext)
  const enabled = createdBy === null || !createdByCurrentUser

  const { data: contactSearch, status } = useRequestOnMount<APIResponse<ContactResponse>>({
    path: buildUserContactQuery(employeeNumber),
    enabled,
  })

  const renderContent = () => {
    if (createdByCurrentUser && userContact) return <Display contact={userContact} />

    if (!enabled) return placeholder

    if (status === 'loading') return <LoadingSpinner />

    const contact = contactSearch?.data?.[0]
    return contact ? <Display contact={contact} /> : placeholder
  }

  return <ReadOnlyField label="Created By">{renderContent()}</ReadOnlyField>
}
