import { FilterQueryParams } from '@app/api/FilterQueryParams'
import React from 'react'

export function useContactFilter(input: 'C' | 'E'){

    const fq = new FilterQueryParams()

    const filter = React.useMemo(() => { const contactCode = fq.textFilter({ field: 'contactType.code', operation: 'equals', value: input })
      const excludedDuplicates = fq.textFilter({ field: 'inactiveReason.reason', operation: 'notEqual', value: 'Duplicate' })
      const includedNullInactiveReason = fq.textFilter({ field: 'inactiveReason', operation: 'empty' })
      const includedInactiveContacts = fq.group(fq.or(excludedDuplicates, includedNullInactiveReason))
      const output = fq.group(fq.and(includedInactiveContacts,contactCode))

      return encodeURIComponent(output)}, [input])


    return filter
}